import React from "react";

const Footer = () => {
  return (
    <div className="py-6 bg-grey-200 w-full text-left xl:relative 2xl:fixed 2xl:bottom-0">
      <p className="text-[18px] text-[#1D2939] font-normal pl-10 max-w-[1440px] m-auto">
        &#169;2023 GoodCause
      </p>
    </div>
  );
};

export default Footer;
