import React from "react";
import banner1 from "../assets/banner1.svg";
import banner2 from "../assets/banner2.svg";
import banner3 from "../assets/banner3.svg";
import pic1 from "../assets/pic1.png";
import pic2 from "../assets/pic2.png";
import pic3 from "../assets/pic3.png";
import logo from "../assets/logo.png";
import logo1 from "../assets/logo1.svg";
import singleLogo from "../assets/singleLogo.png";
// import groupLogo from "../assets/d.mp4";
import groupLogo from "../assets/groupImg.png";

import RightArrow from "../assets/rightArrow.png";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./swipperStyle.css";
import { Divider, Grid, Typography } from "@mui/material";
const Home = () => {
  return (
    <>
      <div className="!bg-back-primar align-middle pl-10 mb-[155px] w-full">
        <Grid container alignItems={"center"}>
          <Grid item xs={10} md={6}>
            <Grid container item>
              <div>
                <div className="text-blue-primary text-left">
                  <div className="flex">
                    <div>
                      <Divider
                        sx={{
                          width: "14px",
                          marginTop: "14px",
                          backgroundColor: "#667085",
                          marginRight: "3px",
                        }}
                      />{" "}
                    </div>
                    <div>
                      <Typography
                        variant="subtitle1"
                        className="text-[#667085]"
                      >
                        Coming soon
                      </Typography>
                    </div>
                  </div>

                  <Typography className="!text-[26px] sm:!text-[32px] !font-bold pt-4">
                    Connecting
                  </Typography>

                  <Typography className="!text-[40px] pt-2 !leading-none sm:!text-[60px] !font-bold">
                    Volunteers,{" "}
                    <span className="text-blue-secondary">
                      Non-
                      <br />
                      Profits
                    </span>
                    &nbsp;and Donors
                  </Typography>
                  <p className="text-[12px] sm:text-[20px] text-grey-500 font-normal pt-[40px]">
                    Get pumped! We have a chance to make a difference <br /> for
                    those in need!
                  </p>
                </div>
                <div className="mt-[16px] px-2 bg-grey-200 rounded-lg flex justify-between">
                  <div className="py-3 px-2 rounded-lg">
                    <input
                      placeholder="Enter your email address"
                      type="email"
                      className="text-[12px] sm:text-[14px]"
                      style={{
                        color: "black",
                        outline: "none",
                        backgroundColor: "#EAECF0",
                      }}
                    />
                  </div>
                  <div className="mt-[0.50rem]">
                    <div className="px-3 py-[0.35rem] text-gc-primary bg-green rounded-lg max-w-[130px] cursor-pointer text-[10px] sm:text-[12px] flex gap-2 items-center">
                      <p>Subscribe</p>
                      {/* <img src={RightArrow} alt="img2" /> */}
                      <ArrowRightAltIcon className="ml-2" />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            md={6}
            alignItems="center"
            className="flex"
            sx={{
              backgroundColor:
                "linear-gradient(148.79deg, rgba(145, 224, 228, 0.3) 12.9%, rgba(0, 126, 133, 0.3) 29.15%, rgba(83, 195, 202, 0.3) 41.54%, rgba(0, 63, 66, 0.3) 54.7%, rgba(166, 233, 237, 0.3) 70.18%, rgba(0, 94, 100, 0.3) 87.21%)",
            }}
          >
            {/* <video width="750" height="500" autoPlay muted loop>
              <source src={groupLogo} type="video/mp4" />
            </video> */}

            <div
              style={
                {
                  // width: "50%",
                  // position: "relative",
                }
              }
              className="hidden sm:block"
            >
              <div id="div1">
                <img src={pic2} alt="img2" />
              </div>

              <div id="div3">
                <img src={pic3} alt="img3" />
              </div>
              <div id="div2">
                {" "}
                <img src={pic1} alt="img1" />
              </div>
            </div>
            <div className="px-10 py-4 block sm:hidden ">
              <img src={groupLogo} alt="img2" />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Home;
