import "./App.css";
import Footer from "./pages/Footer";
import TopNav from "./pages/TopNav";
import AppRoutes from "./routes";

function App() {
  return (
    <>
      <div className="">
        <TopNav />
        <div className="max-w-[1440px] mx-auto  flex items-center min-h-[100vh] justify-center">
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;

