import React from "react";
import logo1 from "../assets/logo1.svg";

const TopNav = () => {
  return (
    <div className="max-w-[1440px] mx-auto w-full h-[80px]">
      <img src={logo1} alt="img" className="py-4 pl-10" />
    </div>
  );
};

export default TopNav;
